<template>
  <app-side-menu :min-width="18"
    :fixed-menu="false">
    <aside class="menu"
      slot="side-menu">
      <p class="menu-label">
        <span class="title is-6">
          Filters
        </span>
      </p>
      <ul class="menu-list">
        <li>
          <div class="field">
            <div class="control">
              <input class="input is-capitalized"
                :class="[`input-${$user.info.theme}`]"
                type="text"
                placeholder="Vendor Name"
                v-model="filter.name"
                @keyup.enter="onFilterClick()">
            </div>
          </div>
          <div class="field">
            <div class="control">
              <input class="input is-capitalized"
                :class="[`input-${$user.info.theme}`]"
                type="text"
                placeholder="Street"
                v-model="filter.street"
                @keyup.enter="onFilterClick()">
            </div>
          </div>
          <div class="field">
            <div class="control">
              <input class="input is-capitalized"
                :class="[`input-${$user.info.theme}`]"
                type="text"
                placeholder="Suburb"
                v-model="filter.suburb"
                @keyup.enter="onFilterClick()">
            </div>
          </div>
          <div class="field">
            <div class="control">
              <input class="input is-capitalized"
                :class="[`input-${$user.info.theme}`]"
                type="text"
                placeholder="ABN"
                v-model="filter.abn"
                @keyup.enter="onFilterClick()">
            </div>
          </div>
          <div class="field has-addons">
            <bulma-radio-button :native-value="0"
              v-model="filter.active">
              <span class="icon is-small">
                <i class="mdi mdi-adjust" />
              </span> Both
            </bulma-radio-button>
            <bulma-radio-button type="is-success"
              :native-value="1"
              v-model="filter.active">
              <span class="icon is-small">
                <i class="mdi mdi-check" />
              </span> Active
            </bulma-radio-button>
            <bulma-radio-button type="is-danger"
              :native-value="2"
              v-model="filter.active">
              <span class="icon is-small">
                <i class="mdi mdi-close" />
              </span> In-Active
            </bulma-radio-button>
          </div>
        </li>
      </ul>
      <hr>
      <div class="field is-grouped is-pulled-right">
        <div class="control">
          <button class="button is-primary"
            @click="onFilterClick">
            <span class="icon is-small">
              <i class="mdi mdi-magnify mdi-18px" />
            </span>
            <span>Filter</span>
          </button>
        </div>
        <div class="control">
          <button class="button"
            @click="onResetClick">
            <span class="icon is-small">
              <i class="mdi mdi-refresh mdi-18px" />
            </span>
            <span>Reset</span>
          </button>
        </div>
      </div>
    </aside>
  </app-side-menu>
</template>

<script>
import AppSideMenu from '@/components/AppSideMenu'
import { BulmaRadioButton } from '@/components/BulmaRadio'

export default {
  name: 'VendorListSideMenu',
  components: {
    AppSideMenu,
    BulmaRadioButton
  },
  props: {
    filter: null
  },
  data() {
    return {
      selectedActive: 1,
      stateList: null
    }
  },
  computed: {},
  created() {},
  methods: {
    onFilterClick() {
      this.$emit('filter')
    },
    onResetClick() {
      this.$emit('reset')
    }
  }
}
</script>
